import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../../_models/auth/user';

const loginUrl = `${environment.apiUrl}login`;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  userToken: any;
  decodeToken: any;

  constructor(private router: Router, private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): User {
    if (!this.currentUserSubject.value) {
      const userObj = this.getUserFromLocalStorage();
      if (userObj) {
        const userSub = new BehaviorSubject<User>(userObj);
        return userSub.value;
      }
    }
    return this.currentUserSubject.value;
  }

  private getUserFromLocalStorage() {
    const currentUserStr = localStorage.getItem('currentUser');
    if (currentUserStr) {
      try {
        const parsedCurrentUser = JSON.parse(currentUserStr);
        return parsedCurrentUser;
      } catch (e) {
        return null;
      }
    }
  }

  login(email: string, password: string) {
    let requestModel = {
      email: email,
      password: password
    };
    const headers = { 'Content-Type': 'application/json' }
    return this.http.post<any>(loginUrl, requestModel, { headers: headers }).toPromise().then(response => {
      if (response && response.user) {
        let x = response.user;
        let user = new User({
          Id: x.id,
          Name: x.name,
          Surname: x.surname,
          MagazaId: x.magaza_id,
          Telefon: x.telefon,
          Adres: x.adres,
          Gorev: x.gorev,
          Durum: x.durum,
          Email: x.email,
          EmailVerifiedAt: x.email_verified_at,
          CreatedAt: x.created_at,
          UpdatedAt: x.updated_at,
          DeletedAt: x.deleted_at,
          Token: response.access_token
        });
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject = new BehaviorSubject<User>(user);
        this.currentUser = this.currentUserSubject.asObservable();
        return user;
      }
    }).catch(x => {
      console.log(x);
      return false;
    });
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentPersonelId');
    this.currentUserSubject.next(null);
    this.router.navigate(['/auth/login']);
  }

}

