import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Ana Sayfa',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    title: true,
    name: 'Yönetim'
  },
  {
    name: 'Ürün Stok Yönetimi',
    url: '/stock-management',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Stok Kategori',
        url: '/stock-management/stock-category',
        icon: 'icon-puzzle'
      },
      {
        name: 'Marka',
        url: '/stock-management/brand',
        icon: 'icon-puzzle'
      },
      {
        name: 'Model',
        url: '/stock-management/model',
        icon: 'icon-puzzle'
      },
      {
        name: 'Grup',
        url: '/stock-management/group',
        icon: 'icon-puzzle'
      },
      {
        name: 'Stok Kart',
        url: '/stock-management/stock',
        icon: 'icon-puzzle'
      },
      {
        name: 'Ürün Reçetesi',
        url: '/stock-management/urun-recete',
        icon: 'icon-puzzle'
      },
      {
        name: 'Alış Fatura',
        url: '/stock-management/alis-fatura',
        icon: 'icon-puzzle'
      },
      {
        name: 'Satış Fatura',
        url: '/stock-management/satis-fatura',
        icon: 'icon-puzzle'
      },
      {
        name: 'Alış Sipariş',
        url: '/stock-management/alis-siparis',
        icon: 'icon-puzzle'
      },
      {
        name: 'Satış Sipariş',
        url: '/stock-management/satis-siparis',
        icon: 'icon-puzzle'
      },
      {
        name: 'Depo',
        url: '/stock-management/depo',
        icon: 'icon-puzzle'
      },
    ]
  },
  {
    name: 'Muhasebe Yönetimi',
    url: '/accounting-management',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Müşteriler',
        url: '/accounting-management/customers',
        icon: 'icon-puzzle'
      },
      {
        name: 'Personeller',
        url: '/accounting-management/personnel',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tahsilat/ Ödeme',
        url: '/accounting-management/collection',
        icon: 'icon-puzzle'
      },
      {
        name: 'Kasa',
        url: '/accounting-management/cash-desk',
        icon: 'icon-puzzle'
      }
    ]
  },
  {
    name: 'Mağaza Yönetimi',
    url: '/accounting-management/shop',
    icon: 'icon-speedometer',
  },
];
