import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_services/auth/authentication-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  loginModel: any =  {
    userName : '',
    password : ''
  } 
  constructor(private authService : AuthenticationService, private router : Router){

  }
  ngOnInit(){

  }

  login(){
     this.authService.login(this.loginModel.userName,this.loginModel.password).then(user=>{
      if(user!= null){
          return this.router.navigate(['/dashboard'])
      }
    });
  }
 }
