export class User{
    public Id = 0;
    public Name = "";
    public Surname = "";
    public MagazaId = 0;
    public Telefon = "";
    public Adres = "";
    public Gorev = "";
    public Durum = "";
    public Email = "";
    public EmailVerifiedAt = "";
    public CreatedAt = "";
    public UpdatedAt = "";
    public DeletedAt = "";
    public Token = "";
    constructor(fields? : {
        Id : number;
        Name : string;
        Surname : string;
        MagazaId : number;
        Telefon : string;
        Adres : string;
        Gorev : string;
        Durum : string;
        Email : string;
        EmailVerifiedAt : string;
        CreatedAt : string;
        UpdatedAt : string;
        DeletedAt : string;
        Token : string;
    }){
        Object.assign(this,fields);
    }
}