import { Injectable }                                                       from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/auth/authentication-service.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {

          if (state.url === '/login') {
            this.router.navigate(['/dashboard']);
            return false;
          }
            return true;
        }

       if (!state.url.startsWith('/login')) {
         this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
         return false;
       }

      return true;
    }
}
